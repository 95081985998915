<template>
     <div>
        <CModalExtended
            color="dark"
            size="lg"
            :closeOnBackdrop="false"
            :show.sync="$store.state.contenedores.modalCargos"
            :title="tituloModal"
            :close-on-backdrop="false"
        >
            <CRow>
                 <CCol sm="12" lg="12" >
                    <CustomTabs :activeTab="activeTab" @update:activeTab="handleTab">
                        <CustomTab :title="$t('label.container')" >

                            <CRow class="justify-content-center">
                                <CCol sm="11" lg="11" xl="11">
                                    <CRow class="justify-content-center">
                                        <CCol sm="12" lg="5" xl="5">
                                            <div class="preview">    
                                                <picture-input
                                                    ref="imageInput"
                                                    :imgSrc="formCargo.Image_url"
                                                    width="200"
                                                    height="200"
                                                    accept="image/jpeg,image/png,image"
                                                    size="10"
                                                    :custom-strings="imgInputTexts"
                                                    @change="handleFileUpload"
                                                    v-if="$store.state.contenedores.modalCargos"
                                                >
                                                </picture-input>
                                            </div>
                                            <div v-if="$v.formCargo.Route.$error">
                                                <div class='text-invalid-feedback text-center' v-if="!$v.formCargo.Route.isValidFile">{{$t('label.badImage')}}</div>
                                            </div>
                                        </CCol>
                                        <CCol sm="12" lg="6" xl="6">
                                            <CRow>
                                                <CCol sm="12" lg="12" xl="12" >
                                                    <CInput
                                                        :label="$t('label.name')"
                                                        addLabelClasses="required text-right"
                                                        :horizontal="{label:'col-sm-12 col-lg-4 ', input: 'col-sm-12 col-lg-8'}"
                                                        v-uppercase
                                                        :placeholder="$t('label.groupName')"
                                                        v-model="$v.formCargo.TpCargoName.$model"
                                                        @blur="$v.formCargo.TpCargoName.$touch()"
                                                        :addInputClasses="{ 'is-invalid': $v.formCargo.TpCargoName.$error }"
                                                        required
                                                        :invalid-feedback="errorMessage($v.formCargo.TpCargoName)"
                                                        :is-valid="hasError($v.formCargo.TpCargoName)"
                                                    >
                                                    </CInput>
                                                </CCol>
                                                <CCol sm="12" lg="12" xl="12" >
                                                    <CInput
                                                        :label="$t('label.typecargocode')"
                                                        addLabelClasses="required  text-right"
                                                        v-uppercase
                                                        :horizontal="{label:'col-sm-12 col-lg-4 ', input: 'col-sm-12 col-lg-8'}"
                                                        :placeholder="$t('label.typecargocode')"
                                                        v-model="$v.formCargo.TpCargoCode.$model"
                                                        @blur="$v.formCargo.TpCargoCode.$touch()"
                                                        :addInputClasses="{ 'is-invalid': $v.formCargo.TpCargoCode.$error }"
                                                        required 
                                                        :invalid-feedback="errorMessage($v.formCargo.TpCargoCode)"
                                                        :is-valid="hasError($v.formCargo.TpCargoCode)"
                                                        >
                                                    </CInput>
                                                </CCol>
                                                <CCol sm="12">
                                                    <CSelect
                                                        :label="$t('label.accesories')"
                                                        addLabelClasses="required text-right"
                                                        :horizontal="{label:'col-sm-12 col-lg-4 ', input: 'col-sm-12 col-lg-8'}"
                                                        :addInputClasses="{ 'is-invalid': $v.formCargo.FgAccesory.$error }"
                                                        @blur="$v.formCargo.FgAccesory.$touch()"
                                                        v-model="formCargo.FgAccesory"
                                                        :value.sync="formCargo.FgAccesory"
                                                        :options="selectOptionsAccesory"
                                                        :invalid-feedback="errorMessage($v.formCargo.FgAccesory)"
                                                        :is-valid="hasError($v.formCargo.FgAccesory)"
                                                    >
                                                        <!--
                                                        <template #invalid-feedback v-if="$v.formCargo.FgAccesory.$error">
                                                            <div class='text-danger' v-if="!$v.formCargo.FgAccesory.required">{{$t('label.required')}}</div>
                                                        </template> -->
                                                    </CSelect>
                                                </CCol>
                                                <CCol sm="12">
                                                    <CSelect
                                                        :label="$t('label.status')"
                                                        addLabelClasses="required text-right"
                                                        :horizontal="{label:'col-sm-12 col-lg-4 ', input: 'col-sm-12 col-lg-8'}"
                                                        :is-valid="formCargo.FgActTpCargo"
                                                        v-if="!idIndentification"
                                                        v-model="formCargo.FgActTpCargo"
                                                        :value.sync="formCargo.FgActTpCargo"
                                                        :options="selectOptions"
                                                    />
                                                </CCol>
                                                <CCol sm="12">
                                                    <CTextarea
                                                        :label="$t('label.description')"
                                                        add-label-classes="text-right"
                                                        type="textarea"
                                                        :placeholder="$t('label.forAllTypeLoadsInGeneral')"
                                                        v-uppercase
                                                        :horizontal="{label:'col-sm-12 col-lg-4 ', input: 'col-sm-12 col-lg-8'}"
                                                        v-model="$v.formCargo.TpCargoDs.$model"
                                                        :addInputClasses="{ 'is-invalid': $v.formCargo.TpCargoDs.$error }"
                                                        @blur="$v.formCargo.TpCargoDs.$touch()"
                                                        :plaintext="false"
                                                        :readonly="false"
                                                        :lazy="(true,400)"
                                                        :invalid-feedback="errorMessage($v.formCargo.TpCargoDs)"
                                                        :is-valid="hasError($v.formCargo.TpCargoDs)"
                                                    >
                                                    </CTextarea>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                </CCol>

                            </CRow>               
                        </CustomTab>
                        <CustomTab :title="$t('label.isoCode')" >

                            <CRow class="mt-2 justify-content-center">
                                <CCol sm="11" lg="10" xl="10">
                                    <CCard>
                                        <CCardHeader>
                                            <CRow class="mt-2 justify-content-center">
                                                <CCol sm="11" lg="10" xl="10">
                                                    <CSelect
                                                        :label="$t('label.isoStandar')"
                                                        addLabelClasses="required text-right"
                                                        :horizontal="{label:'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11 col-lg-6 col-xl-6'}"
                                                        :value="formStandartd.StandardDocumentId"
                                                        :addInputClasses="{ 'is-invalid': $v.formStandartd.StandardDocumentId.$error }"
                                                        @blur="$v.formStandartd.StandardDocumentId.$touch()"
                                                        @change="formStandartd.StandardDocumentId = $event.target.value"
                                                        :options="optionList"
                                                        :is-valid="hasError($v.formStandartd.StandardDocumentId)"
                                                        :invalid-feedback="errorMessage($v.formStandartd.StandardDocumentId)"
                                                    >
                                                        <!--template #invalid-feedback v-if="$v.formStandartd.StandardDocumentId.$error">
                                                            <div class='text-invalid-feedback' v-if="!$v.formStandartd.StandardDocumentId.required">{{$t('label.required')}}</div>
                                                        </template-->
                                                    </CSelect>
                                                </CCol>
                                            </CRow>
                                        </CCardHeader>
                                        <CCardBody>
                                            <CCol sm="11" lg="12" xl="12">
                                                <CRow class="justify-content-center">
                                                    <CCol sm="11" lg="7" xl="7" class="px-0">
                                                        <CInput
                                                            :label="$t('label.isoCode')"
                                                            addLabelClasses="required text-right"
                                                            :horizontal="{label:'col-sm-11 col-lg-5', input:'col-sm-11 col-lg-7'}"
                                                            v-uppercase
                                                            :placeholder="$t('label.enter')+' '+$t('label.isoCode')"
                                                            v-model="$v.formStandartd.MetadataDesc.$model"
                                                            :addInputClasses="{ 'is-invalid': $v.formStandartd.MetadataDesc.$error }"
                                                            :invalid-feedback="errorMessage($v.formStandartd.MetadataDesc)"
                                                            :is-valid="hasError($v.formStandartd.MetadataDesc)"
                                                            required 
                                                            >
                                                            
                                                        </CInput>
                                                    </CCol>
                                                    <CCol sm="1" lg="2" xl="2" class="text-center">
                                                        <CButton 
                                                            color="add"
                                                            size="sm"
                                                            v-c-tooltip="{
                                                                content: $t('label.add'),
                                                                placement: 'top-end'
                                                            }"
                                                            @click="updatedTable()"
                                                            >
                                                                <CIcon name="checkAlt"/>
                                                        </CButton>
                                                    </CCol>
                                                    <CCol sm="11" lg="7" xl="7" class="px-0" v-if="idIdentificacionStandartd">
                                                        <CSelect
                                                            :label="$t('label.status')"
                                                            addLabelClasses="required text-right"
                                                            :horizontal="{label:'col-sm-11 col-lg-5', input:'col-sm-11 col-lg-7'}"
                                                            :is-valid="formStandartd.FgActMetadata"
                                                            v-model="formStandartd.FgActMetadata"
                                                            :value.sync="formStandartd.FgActMetadata"
                                                            :options="selectOptions"
                                                        />
                                                    </CCol>
                                                    
                                                </CRow>
                                            </CCol>   



                                            <CCol sm="12" class="mt-3">
                                                <dataTableExtended
                                                    class="align-center-row-datatable"
                                                    :items="formatedItems"
                                                    :fields="fields"
                                                    sorter
                                                    :table-filter="tableText.tableFilterText"
                                                    :items-per-page-select="tableText.itemsPerPageText"
                                                    :noItemsView="tableText.noItemsViewText"
                                                    :items-per-page="5"
                                                    pagination
                                                    column-filter
                                                    hover
                                                    
                                                >
                                                    <template #MetadataId="{ index}">
                                                        <td class="center-cell">
                                                            {{index+1}}
                                                        </td>
                                                    </template>
                                                    <template #Status="{item}">
                                                        <td class="center-cell">
                                                            <CBadge v-if="item.status" color="success">
                                                                {{ $t('label.'+item.Status) }}
                                                            </CBadge>
                                                            <CBadge v-if="!item.status" color="danger">
                                                                {{ $t('label.'+item.Status) }}
                                                            </CBadge>
                                                        </td>
                                                    </template>
                                                    <template #acciones="{item,index}">
                                                        <td class="center-cell">
                                                                <CButton
                                                                    color="edit"
                                                                    square
                                                                    size="sm"
                                                                    class="mr-1"
                                                                    @click="editTable(item,index)"
                                                                    v-c-tooltip="{
                                                                        content: $t('label.edit'),
                                                                        placement: 'top-end'
                                                                    }"
                                                                >
                                                                    <CIcon name="pencil"/>
                                                                </CButton>
                                                        </td>
                                                    </template>
                                                </dataTableExtended>
                                            </CCol>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                        </CustomTab>
                    </CustomTabs>
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateFormLoading"
                >
                    <div v-if="apiStateFormLoading">
                        <span  class="spinner-border spinner-border-sm" role="Accesory" aria-hidden="true"></span>&nbsp;
                        {{this.$t('button.accept')}}
                    </div>

                    <div v-if="!apiStateFormLoading">
                        <CIcon name="checkAlt"/>&nbsp;
                        {{this.$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    class="m-2"
                    :disabled="apiStateFormLoading"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { FormCargo } from '@/_validations/contenedores/ContenedoresValidation';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import TipoGruaMixin from '@/_mixins/tipo-grua';
    import { GenerateImgPlaceholder } from '@/_helpers/funciones';
    import PictureInput from '@/components/picture-input';
    import { validURL, errorMessage, alertPropertiesHelpers } from '@/_helpers/funciones';
    import CustomTabs from '@/components/tabs/CustomTabs';
    import CustomTab from '@/components/tabs/CustomTab';

    function fields(){
        return [
            { key: 'MetadataId', label: '#',filter:false, _style: 'width:3%; text-align:center', },
            { key: 'MetadataDesc',label: this.$t('label.name'), _style:'width:35%;' },
            { key: 'StandardDocumentName',label: this.$t('label.Estandar'), _style:'width:35%;' },
            { key: 'Status',label: this.$t('label.status'), _style:'width:10%; text-align:center;' },
            { 
                key: 'acciones', 
                label: '', 
                _style: 'min-width: 50px; width:4%',
                sorter: false, 
                filter: false
            }
        ];
    }
    //data
    function data() {
        let _lang = this.$i18n.locale;
        return {
            formCargo:{
                TpCargoId:0,
                TpCargoName:'',
                TpCargoDs:'',
                TpCargoCode:'',
                FgAccesory:true,
                Route:'',
                DocumentJson:[],
                Image_url:_lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg',
                FgActTpCargo:true,
            },
            formStandartd:{
                position:null,
                StandardDocumentId:'',
                MetadataDesc:'',
                FgActMetadata:true,
            },
            
            Image_original:null,
            myDataCombo:[],
            Image_original:null,
            originalAct: '',
            tituloModal: this.$t('label.nuevo')+' '+this.$t('label.group'),
            activeTab: 0,
            activePage: 1,
        }
    }



    //methods
    function cerrarModal(){
        if(this.apiStateFormLoading)
            return
        this.$store.state.contenedores.modalCargos = false;
    }
    function registerData(){
        try {
            this.$v.formCargo.$touch();
            if (this.$v.formCargo.$pending || this.$v.formCargo.$error)
                throw this.$t('label.errorsPleaseCheck');

            if (this.$v.formCargo.$pending || this.$v.formCargo.$error) return;

            let { TpCargoId,TpCargoName,TpCargoDs,TpCargoCode,Route,DocumentJson,FgActTpCargo,FgAccesory } = this.formCargo;
            const { dispatch } = this.$store;
            const Image_original = this.Image_original;
            //var source = 'https://www.google.es/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png';
            
            if(this.originalAct !== '' && TpCargoId !== 0){
                if(this.originalAct !== FgActTpCargo){
                    this.$swal.fire(
                            alertPropertiesHelpers(this, {
                                text: `${this.$t('label.changeStatusQuestion')+' '} ${TpCargoName}?`,
                            })
                        ).then((result) => {
                        if (result.isConfirmed) {
                            dispatch('contenedores/dataGrupo', { TpCargoId,TpCargoName,TpCargoDs,TpCargoCode,Route,DocumentJson,FgActTpCargo,Image_original,FgAccesory });
                        }else {
                            //this.$swal(this.$t('label.operationCancelled'));
                        }
                    })
                }else{
                    dispatch('contenedores/dataGrupo', { TpCargoId,TpCargoName,TpCargoDs,TpCargoCode,Route,DocumentJson,FgActTpCargo,Image_original,FgAccesory });
                }
            }else{
                dispatch('contenedores/dataGrupo', { TpCargoId,TpCargoName,TpCargoDs,TpCargoCode,Route,DocumentJson,FgActTpCargo,Image_original,FgAccesory });
            }
        } catch (e) {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: e,
                type: "error"
            });
        }
        //
    }
    function handleFileUpload(files, e) {
        //this.formCargo.Image_url = '';
        this.formCargo.Route = this.$refs.imageInput.file;
        this.$v.formCargo.Route.$touch();     
    }
    function updatedTable(){
        try { 
            this.$v.formStandartd.$touch();
            if (this.$v.formStandartd.$pending || this.$v.formStandartd.$error)
                throw this.$t('label.errorsPleaseCheck');

            if (this.$v.formStandartd.$pending || this.$v.formStandartd.$error) return;

            const idStandarDocument = this.formStandartd.StandardDocumentId;

            let positionCombo = this.myDataCombo.map(function(e) {
                return e.StandardDocumentId; 
            }).indexOf(idStandarDocument);

            if(this.formStandartd.position === null){
                this.formCargo.DocumentJson.push({
                    MetadataId: '',
                    StandardDocumentId: this.myDataCombo[positionCombo].StandardDocumentId,
                    StandardDocumentName: this.myDataCombo[positionCombo].StandardDocumentName,
                    StandardDocumentDescription: this.myDataCombo[positionCombo].StandardDocumentDescription,
                    StandardOrganization: this.myDataCombo[positionCombo].StandardOrganization,
                    MetadataDesc: this.formStandartd.MetadataDesc,
                    FgActMetadata: true,
                    Status: "ACTIVO",
                });
            }else{
                const positionOriginal = this.formStandartd.position;
                this.formCargo.DocumentJson[positionOriginal].StandardDocumentId = this.myDataCombo[positionCombo].StandardDocumentId;
                this.formCargo.DocumentJson[positionOriginal].StandardDocumentName = this.myDataCombo[positionCombo].StandardDocumentName;
                this.formCargo.DocumentJson[positionOriginal].StandardDocumentDescription = this.myDataCombo[positionCombo].StandardDocumentDescription;
                this.formCargo.DocumentJson[positionOriginal].StandardOrganization = this.myDataCombo[positionCombo].StandardOrganization;
                this.formCargo.DocumentJson[positionOriginal].MetadataDesc = this.formStandartd.MetadataDesc;
                this.formCargo.DocumentJson[positionOriginal].FgActMetadata = this.formStandartd.FgActMetadata;
                this.formCargo.DocumentJson[positionOriginal].Status = this.formStandartd.FgActMetadata ? "ACTIVO" : "INACTIVO";
            }
            this.formStandartd.position = null;
            this.formStandartd.StandardDocumentId = '';
            this.formStandartd.MetadataDesc = '';
            this.formStandartd.FgActMetadata = true;
            this.$nextTick(() => { this.$v.formStandartd.$reset() })

        } catch (e) {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: e,
                type: "error"
            });
        }

    }
    function editTable(item,index){
       this.formStandartd.position = index;
       this.formStandartd.MetadataDesc = item.MetadataDesc;
       this.formStandartd.StandardDocumentId = item.StandardDocumentId;
       this.formStandartd.FgActMetadata = item.FgActMetadata;
       this.$v.formStandartd.MetadataDesc.$touch();
    }

    function handleTab(tab) {
        this.activeTab = tab;
    }
    
    function isActiveTab(current) {
        return this.activeTab == current;
    }

    //computed
    function idIndentification(){
        return this.formCargo.TpCargoId === 0;
    }
    function idIdentificacionStandartd(){
        return this.formStandartd.position !== null;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    function formatedItems() {
        let computedItems = this.formCargo.DocumentJson;
       
        if(computedItems.length !== 0){
            computedItems = computedItems.map((item) => Object.assign({}, item, {
                status: item.FgActMetadata,
                _classes: ( item.FgActMetadata ) ? '' : 'table-danger'
            }));
        }
        return computedItems.filter(item => {
            return item
        })
    }
    function optionList(){
        if(this.myDataCombo.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataCombo.map(function(e){
                if(e.FgActStandardDocument){
                    chart.push({
                        value: e.StandardDocumentId, 
                        label: e.StandardDocumentName,
                    })
                }
            })
            return chart;
        }
    }
    function selectOptionsAccesory() {
        return [
            { 
                value: true, 
                label: this.$t('label.apply')
            },
            { 
                value: false, 
                label: 'N/A'
            }
        ];
    }
    function selectOptions(){
        return [
            { 
                value: true, 
                label: this.$t('label.ACTIVO')
            },
            { 
                value: false, 
                label: this.$t('label.INACTIVO')
            }
        ];
    }
    //watch
    function modalCargos(newQuestion){
        this.activeTab = 0;
        let _lang = this.$i18n.locale;
        if(newQuestion === false){

            this.formCargo.TpCargoId=0;
            this.formCargo.TpCargoName='';
            this.formCargo.TpCargoDs='';
            this.formCargo.TpCargoCode='';
            this.formCargo.Route='';
            this.formCargo.Image_url = '';
            this.formCargo.FgAccesory = true;
            this.formCargo.DocumentJson = [];
            this.formStandartd.MetadataDesc = '';
            this.formStandartd.StandardDocumentId='';
            this.formStandartd.position = null;

            this.Image_original = null;
            this.originalAct = '';
            this.tituloModal = this.$t('label.nuevo')+' '+this.$t('label.group'),
            this.Image_original=null,
            this.formStandartd.FgActTpCargo=true;
            this.$nextTick(() => { this.$v.$reset() })
            this.$store.commit('contenedores/asignarid', 0);
        }else{
            if(this.idState !== 0){
                if(this.groupRoute != null && this.groupRoute != ''){
                    this.formCargo.Image_url = this.groupRoute;
                }else{
                    this.formCargo.Image_url = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
                }

            }

            this.tituloModal = this.$t('label.nuevo')+' '+this.$t('label.group'),
            this.$store.state.contenedores.apiStateForm = ENUM.LOADING;
            try {
                this.$http.ejecutar('GET', 'StandardDocument-list', { Filter: 'ALL' }, '')
                .then(
                    combo => {
                        if(combo.status === 200){
                            if(this.idState === 0){
                                this.formCargo.Image_url = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
                                this.myDataCombo = combo.data.data;
                                this.$store.state.contenedores.apiStateForm = ENUM.INIT;
                            }else{
                                const id = this.idState;
                                this.$http.ejecutar('GET', 'TpCargo-by-id',{ TpCargoId: id }, '' ).then(response => {
                                    if(response.status === 200){
                                        const Information = response.data.data;
                                        if(Information.length !== 0){
                                            this.tituloModal = this.$t('label.edit')+' '+this.$t('label.group')+': '+Information[0].TpCargoName;
                                            this.formCargo.TpCargoId=Information[0].TpCargoId;
                                            this.formCargo.TpCargoName=Information[0].TpCargoName;
                                            this.formCargo.TpCargoDs=Information[0].TpCargoDs;
                                            this.formCargo.TpCargoCode=Information[0].TpCargoCode;
                                            this.formCargo.DocumentJson=Information[0].MetadataJson;
                                            this.formCargo.Route=Information[0].Route;
                                            this.formCargo.FgAccesory = Information[0].FgAccesory ? true : false;
                                            const icon = Information[0].Route;
                                            this.Image_original = icon;
                                            /*if(validURL(icon)){
                                                this.formCargo.Image_url = icon;
                                            }else{
                                                this.formCargo.Image_url = icon?`${this.$store.getters["connection/getBase"]}${icon.replace('public/', '', null, 'i')}`:GenerateImgPlaceholder();
                                            }
                                            */
                                            this.originalAct = Information[0].FgActTpCargo;
                                            this.formCargo.FgActTpCargo = Information[0].FgActTpCargo;
                                            this.myDataCombo = combo.data.data;
                                            this.$store.state.contenedores.apiStateForm = ENUM.INIT;
                                        }
                                        this.$v.formCargo.$touch();
                                    }else{
                                        this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                                        this.$store.state.contenedores.modalCargos = false;
                                    }
                                }).catch(err => {
                                    this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                                    this.$store.state.contenedores.modalCargos = false;
                                    this.$store.commit('contenedores/messageMutation', err);
                                });
                            }
                        }else{
                            this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                            this.$store.state.contenedores.modalCargos = false;
                        }   
                    },
                    error => {
                        this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                        this.$store.state.contenedores.modalCargos = false;
                        this.$store.commit('contenedores/messageMutation', error);
                        //dispatch('alert/error', error, { root: true });
                    }
                );
            } catch (error) {
                this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                this.$store.state.contenedores.modalCargos = false;
                this.$store.commit('contenedores/messageMutation', err);
            }
        }
    }

    export default {
        name: 'modal-group',
        data,
        validations(){ return FormCargo() },
        methods:{
            registerData,
            cerrarModal,
            updatedTable,
            editTable,
            handleFileUpload,
            errorMessage,
            isActiveTab,
            handleTab,
        },
        mixins: [TipoGruaMixin],
        components: {
            PictureInput,
            CustomTabs, 
            CustomTab,
        },
        directives: UpperCase,
        computed:{
            idIndentification,
            idIdentificacionStandartd,
            optionList,
            apiStateFormLoading,
            formatedItems,
            fields,
            selectOptions,
            selectOptionsAccesory,
            //tituloModal,
            ...mapState({
                idState: state=> state.contenedores.id,
                apiState: state => state.contenedores.apiState,
                apiStateForm: state => state.contenedores.apiStateForm,
                modalCargos: state=> state.contenedores.modalCargos,
                groupRoute: (state) => state.contenedores.groupRoute,
            })
        },
        watch:{
            modalCargos
        }
    }
</script>
<style lang="scss">
    .center-cell {
        text-align: center;
        }
    .table-index {
        table {
            td {
            vertical-align: middle !important;
            }
        }
    }
    .text-invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e55353;
}
</style>